import { ReactNode } from 'react';
import {
  Accordion as ReactAccordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import styled from 'styled-components';
import tw from 'tailwind-styled-components';
import { WavyCircleIcon } from '@icons/WavyCircle';
import { Title, Text as Paragraph } from '../../../components/Typography';

const StyledAccordion = styled(ReactAccordion)`
  border-bottom: 1px solid #bbc4ba;
  width: 94%;
  margin: 0 3%;
  overflow-x: hidden;
  @media screen and (min-width: 640px) {
    width: 86%;
    margin: 0 7%;
  }

  .accordion__item {
    border-top: 1px solid #bbc4ba;
  }

  .accordion__button {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 18px 0;
    width: 100%;
    text-align: left;
    border: none;
    position: relative;
    padding-right: 25px;
    @media screen and (max-width: 480px) {
      outline: 0;
    }
  }

  .accordion__button::before {
    content: '+';
    font-size: 30px;
    display: block;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .accordion__button[aria-expanded='true']::before,
  .accordion__button[aria-selected='true']::before {
    content: '–';
  }

  [hidden] {
    display: none;
  }

  .accordion__button:hover #title {
    text-decoration: underline;
  }
  .accordion__button:focus #title {
    text-decoration: underline;
  }
`;

const StyledAccordionItemPanel = tw(AccordionItemPanel)`
  relative
  animate-fadeIn
  ${(p: { $isNumbered?: boolean }) => (p.$isNumbered ? 'pl-10 md:pl-20' : '')}
`;

const NumberContainer = tw.div`
  relative
  flex-none
  mr-3
  w-11
  h-11
  md:mr-10
`;

const Number: any = tw(Title as any)`
  absolute
  mt-px
  -inset-0
  flex
  items-center
  justify-center
`;

export const Disclaimer = tw(Paragraph)`
  py-5
`;

const RecipeLink = tw.div`
  hidden
  md:inline-block
`;

export const Accordion = ({
  name,
  data,
  $isNumbered = false,
  ...props
}: {
  name: string;
  data: any;
  collapsed?: boolean;
  $isNumbered?: boolean;
}): ReactNode => (
  <StyledAccordion allowZeroExpanded preExpanded={[`${name}-0`]} {...props}>
    {data?.map((item: any, index: number) => (
      // eslint-disable-next-line react/no-array-index-key
      <AccordionItem uuid={`${name}-${index}`} data-test="item" key={`${name}-${index}`}>
        <AccordionItemHeading data-test="heading">
          <AccordionItemButton data-test="button">
            {$isNumbered && (
              <NumberContainer data-test="accordion-number-container">
                <WavyCircleIcon data-test="accordion-wavy-circle" />
                <Number tag="h3" type="xxxs">
                  {index + 1}
                </Number>
              </NumberContainer>
            )}
            {item.itemTitle}
            <AccordionItemState>
              {(state) =>
                state.expanded ? (
                  <div className="ml-auto relative">
                    <RecipeLink>{item.itemLink}</RecipeLink>
                  </div>
                ) : (
                  <div className="hidden md:block ml-8">{item.itemInfo}</div>
                )
              }
            </AccordionItemState>
            {}
          </AccordionItemButton>
        </AccordionItemHeading>
        {item?.itemTitle ? (
          <StyledAccordionItemPanel $isNumbered={$isNumbered} data-test="accordion-panel">
            <div className="mb-4">{item.itemInfo}</div>
            {item?.itemBody}
            <div className="md:hidden mb-4 flex justify-items-end pt-4">{item.itemLink}</div>
          </StyledAccordionItemPanel>
        ) : (
          <StyledAccordionItemPanel
            $isNumbered={$isNumbered}
            data-test="accordion-panel"
            className="sm:-mt-12 md:-mt-14"
          >
            <div className="md:hidden mb-4">{item.itemLink}</div>
            <div className="mb-4">{item.itemInfo}</div>
            {item?.itemBody}
          </StyledAccordionItemPanel>
        )}
      </AccordionItem>
    ))}
  </StyledAccordion>
);
