export const RightArrow = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5781 6.1875L16.2031 6.51562C16.0156 6.75 16.0156 7.07812 16.2031 7.3125L20.1406 11.2031H2.5625C2.23438 11.2031 2 11.4844 2 11.7656V12.2344C2 12.5625 2.23438 12.7969 2.5625 12.7969H20.1406L16.2031 16.7344C16.0156 16.9688 16.0156 17.2969 16.2031 17.5312L16.5781 17.8594C16.7656 18.0938 17.1406 18.0938 17.375 17.8594L22.8125 12.4219C23.0469 12.1875 23.0469 11.8594 22.8125 11.625L17.375 6.1875C17.1406 5.95312 16.7656 5.95312 16.5781 6.1875Z"
      fill="#292B29"
    />
  </svg>
);
