/* eslint-disable react/jsx-wrap-multilines */
import { BlobUpperCorner } from '@icons/Blobs';
import { RightArrow } from '@icons/RightArrow';
import { ShefIcon } from '@icons/ShefIcon';
import { StarIcon } from '@icons/StarIcon';
import { TimeIcon } from '@icons/TimeIcon';
import { Link } from 'components/Link';
import NextImage from 'components/NextImage';
import { ReactNode } from 'react';
import { Text as Paragraph, Title } from '../../components/Typography';
import { Accordion } from './components/StyledAccordion';
import {
  CategoryText,
  Container,
  Dots,
  Icon,
  ImgContainer,
  Info,
  InfoItem,
  LinkContainer,
  LinkText,
  MetaballContainer,
  StyledTitle,
} from './styles';

import type { GenericAccordionTopRated as TGenericAccordionTopRated } from 'server/repository/hygraph/generated/graphqlTypes';
import type { ISharedTemplateProps } from 'templates/TemplateMapper/types';
import type { RecipeResult } from 'types/Recipes';
import { DictionaryService } from 'service/dictionary/dictionaryService';
import { TrackingService } from 'service/tracking/trackingService';
import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';

interface IGenericAccordionTopRatedContainer extends ISharedTemplateProps {
  templateProps: TGenericAccordionTopRated & {
    algoliaItems: RecipeResult[]; // is added in getGenericExtensionComponents.ts line 63
  };
}

export const GenericAccordionTopRated = ({
  templateProps,
  recipeCopy,
  difficultyCopy,
  templateIndex,
}: IGenericAccordionTopRatedContainer): ReactNode => {
  const { pathTranslations } = useGlobalContext();
  const data = templateProps.algoliaItems;
  const clickTeaserSeeRecipeAccordionContainer = (accordionItemData: any, position: number) => {
    TrackingService.clickTeaserSeeRecipe({
      teaserName: accordionItemData?.Title,
      containerTitle: templateProps.title,
      containerType: 'accordion',
      containerPosition: templateIndex ?? 0 + 1,
      teaserPosition: position || 0,
      teaserRecipeId: accordionItemData?.Id,
      teaserRecipeTitle: accordionItemData?.Title,
      teaserRecipeDifficulty: difficultyCopy?.[accordionItemData?.Difficulty] || 'na',
      teaserRecipeRating: accordionItemData?.Rating,
      teaserRecipeDuration:
        accordionItemData?.TotalTimeInMinutes || accordionItemData?.PreparationTimeInMinutes,
    });
  };

  const name = 'GenericAccordionTopRated';
  const accordionData = data?.slice(0, 5).map((item, index: number) => {
    const itemTitle = (
      // eslint-disable-next-line react/no-array-index-key
      <Title tag="div" type="xs" key={`${item?.title}-${index}`}>
        <div id="title">{item?.Title}</div>
      </Title>
    );
    const itemInfo = (
      <Info tag="div" type="lead">
        {item?.Rating && (
          <>
            <StarIcon />
            <InfoItem>{item?.Rating}</InfoItem>
            <Dots />
          </>
        )}
        {(item?.TotalTimeInMinutes || item?.PreparationTimeInMinutes) && (
          <>
            <TimeIcon />
            <InfoItem>
              {item?.TotalTimeInMinutes || item?.PreparationTimeInMinutes}{' '}
              {DictionaryService.getCopy('minutes', recipeCopy) || 'MIN'}
            </InfoItem>
          </>
        )}
        {(item?.TotalTimeInMinutes || item?.PreparationTimeInMinutes) &&
          !!difficultyCopy &&
          difficultyCopy[item?.Difficulty] && <Dots />}
        {!!difficultyCopy && difficultyCopy[item?.Difficulty] && (
          <>
            <ShefIcon />
            <InfoItem>{!!difficultyCopy && difficultyCopy[item?.Difficulty]}</InfoItem>
          </>
        )}
      </Info>
    );
    const itemLink = (
      <LinkContainer onClick={() => clickTeaserSeeRecipeAccordionContainer(item, index + 1)}>
        <Link href={`${pathTranslations.recipe}${item?.Slug || item?.slug || item?.Id || ''}`}>
          <LinkText tag="span" type="link">
            {DictionaryService.getCopy('see_recipe', recipeCopy) || 'See recipe'}
            <Icon>
              <RightArrow />
            </Icon>
          </LinkText>
        </Link>
      </LinkContainer>
    );
    const itemBody = (
      <>
        {item?.ShortDescription && (
          <Paragraph tag="div" type="md">
            {item?.ShortDescription}
          </Paragraph>
        )}
        {(item?.Images?.[0]?.Formats?.[1]?.Url?.[0]?.url ||
          item?.Images?.[0]?.Formats?.[0]?.Url?.[0]?.url) && (
          <ImgContainer>
            <NextImage
              src={
                item?.Images?.[0]?.Formats?.[1]?.Url?.[0]?.url ||
                item?.Images?.[0]?.Formats?.[0]?.Url?.[0]?.url
              }
              mimeType={String(item?.Images?.[0]?.Formats?.[1]?.Url?.[0]?.mimeType)}
              alt=""
              width={1280}
              height={800}
              className="block w-full"
            />
          </ImgContainer>
        )}
      </>
    );
    return {
      itemTitle,
      itemInfo,
      itemLink,
      itemBody,
    };
  });

  return (
    <Container>
      <MetaballContainer>
        <BlobUpperCorner color="#F4EBB3" />
      </MetaballContainer>
      <CategoryText tag="div" type="lead">
        {templateProps.super}
      </CategoryText>
      <StyledTitle tag="div" type="md">
        <div id="title">{templateProps.title}</div>
      </StyledTitle>
      <Accordion data-test="generic-accordion" name={name} data={accordionData} $isNumbered />
    </Container>
  );
};
