import tw from 'tailwind-styled-components';
import { Title, Text as Paragraph } from '../../components/Typography';

export const Container = tw.div`
  relative
`;

export const MetaballContainer = tw.div`
  absolute
  z-negative
  top-0
  -right-0
  hidden
  md:block
`;

export const Dots = tw.div`
  w-1
  h-1
  rounded
  bg-black
  mx-1
`;

export const ImgContainer = tw.div`
  mt-10
  -ml-10
  mr-0
  md:-ml-20
`;

export const CategoryText = tw(Paragraph)`
  text-center
  opacity-70
  mt-10
`;

export const StyledTitle = tw(Title)`
  text-center
  mt-2.5
  mb-5
  md:mb-10
`;

export const Info = tw(Paragraph)`
  flex
  items-center
`;

export const InfoItem = tw.span`
  ml-1
`;

export const LinkContainer = tw.div`
  ml-auto
  md:mr-20
`;

export const LinkText = tw(Paragraph)`
  flex
  items-center
  text-darkgray
`;

export const Icon = tw.div`
  ml-2
`;
